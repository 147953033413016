import { PageProps, graphql } from "gatsby";
import React from "react";
import PropertyInner from "@global/property/propertyInner";
import { PageContext } from "@util/types";
import { Maybe, SanityProperty, SanityPropertyTemplate } from "@graphql-types";

interface Props extends PageProps {
  data: {
    sanityProperty: Maybe<SanityProperty>;
    sanityPropertyTemplate: Maybe<SanityPropertyTemplate>;
  };
  pageContext: PageContext;
  isPreview?: boolean;
}

export default function PropertyInnerTemplate({ data, pageContext }: Props) {
  return (
    <>
      <PropertyInner data={data} pageContext={pageContext} />
    </>
  );
}

export const query = graphql`
  query PropertyInnerQuery($slug: String) {
    sanityPropertyTemplate {
      components {
        ...sanityComponents
      }
      pageFooter {
        ...sanityPageFooter
      }
      defaultDocuments {
        _key
        _type
        fileName
        fileLink
        file {
          asset {
            url
          }
        }
      }
    }
    sanityProperty(slug: { current: { eq: $slug } }) {
      seo {
        ...sanitySeo
      }
      ...sanityFeaturedProperty
      description
      externalLinks {
        _type
        _key
        title
        url
      }
      geoLocation {
        latitude
        longitude
        accuracy
      }
      homesellAgent {
        phone
        name
        email
      }
      openHomes {
        _key
        startDate
        startTime
        endTime
      }
      sellerDetails {
        mainFirstName
        mainLastName
        mainEmail
        mainPhone
        secondFirstName
        secondLastName
        secondEmail
        secondPhone
        postalAddress {
          city
          postcode
          region
          street
          suburb
          unit
        }
      }
      publicDocuments {
        _key
        _type
        fileName
        fileLink
        file {
          asset {
            url
          }
        }
      }
      privateDocuments {
        _key
        _type
        fileName
        fileLink
        file {
          asset {
            url
          }
        }
      }
    }
  }
`;
